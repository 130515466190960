import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Box } from "../Core";
import { BoxProps } from "../Core/Box";

const Card = styled(Box)<BoxProps>`
  border-radius: 5px;
  transition: 0.4s;
  height: 400px;
  overflow: hidden;
  position: relative;
`;

const CardOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 1;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(25%, #0d333f),
    color-stop(85%, rgba(13, 51, 63, 0))
  );
  background: linear-gradient(90deg, #0d333f 25%, rgba(13, 51, 63, 0) 85%);
  mix-blend-mode: multiply;
`;

const CardContent = styled.div`
  z-index: 2;
  position: relative;
  padding: 20px 30px;
  max-width: 60%;
`;

const CardImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s;
`;

const CardTitle = styled.h4`
  font-size: 32px;
  line-height: 36px;
  color: #fff;
`;
const CardPreTitle = styled.p`
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
`;
const CardText = styled.p`
  color: #fff;
  font-weight: 400;
`;
const Button = styled.div`
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
`

interface ContentProps {
  img?: string;
  preTitle?: unknown;
  title?: ReactNode | string;
  subTitle?: ReactNode | string;
  readMore?: unknown;
  buttonText?: string;
  [propName: string]: unknown;
}

const PostCardContent: React.FC<ContentProps> = ({
  img,
  preTitle,
  title,
  subTitle,
  readMore,
  buttonText,
  children,
  ...rest
}) => {
  const [hover, setHover] = useState(false);
  return (
    <Card
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...rest}
    >
      <CardImage
        style={{
          backgroundImage: `url(${img})`,
          transform: `scale(${hover ? 1.05 : 1})`,
        }}
      />
      <CardOverlay />
      <CardContent>
        <CardPreTitle>CASE STUDY</CardPreTitle>
        <CardTitle>{title}</CardTitle>
        <CardText>{children}</CardText>
        <Button>
          READ MORE
        </Button>
      </CardContent>
    </Card>
  );
};

interface Props {
  img?: string;
  preTitle?: unknown;
  title?: ReactNode | string;
  subTitle?: ReactNode | string;
  readMore?: unknown;
  to?: string;
  buttonText?: string;
  [propName: string]: unknown;
}

const CardCaseStudy = ({ to, ...rest }: Props) => {
  return to ? (
    <Link to={to}>
      <PostCardContent {...rest} />
    </Link>
  ) : (
    <PostCardContent {...rest} />
  );
};

export default CardCaseStudy;
