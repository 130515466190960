import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useStaticQuery, graphql } from "gatsby";

import { Title, Section, Box, Text, A, Span } from "../../components/Core";
import CardCaseStudy from "../../components/CaseStudyCard/CaseStudyCard";
import Container from "../../components/Layout/Container";

type Frontmatter = {
  frontmatter: {
    slug: string;
    client: {
      overviewTextPara1: string;
    };
    hero: {
      title: string;
      subtitle: string;
      quote: string;
      listImage: {
        childImageSharp: {
          fluid: {
            src: string | undefined;
          };
        };
      };
      brandImage: {
        childImageSharp: {
          fluid: {
            src: string | undefined;
          };
        };
      };
    };
  };
};

const Testimonial = () => {
  const data = useStaticQuery(graphql`
    query CaseListTestimonial {
      allMarkdownRemark(
        filter: {
          frontmatter: { type: { eq: "case-study" }, listGroup: { ne: "none" } }
        }
      ) {
        nodes {
          frontmatter {
            slug
            client {
              overviewTextPara1
            }
            hero {
              title
              subtitle
              quote
              listImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              brandImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const result = data.allMarkdownRemark.nodes.slice(0, 2);
  return (
    <>
      {/* <!-- testimonial section --> */}
      <Section bg="#f7f7fb">
        <Container>
          <Box pb={[4, null, null, '40px']}>
            <Row className="justify-content-center">
              <Col lg="7" xl="6">
                <div className="section-title text-center">
                  <Title>Case Studies</Title>
                </div>
              </Col>
            </Row>
          </Box>

          <Row className="d-flex flex-wrap align-items-stretch justify-content-start">
            {result.map((node: Frontmatter) => {
              const { hero, slug, client } = node.frontmatter;
              return (
                <Col md="6" className="mb-5 ">
                  <CardCaseStudy
                    key={slug}
                    img={hero.listImage.childImageSharp.fluid.src}
                    imgBrand={hero.brandImage.childImageSharp.fluid.src}
                    title={hero.title}
                    subTitle={hero.subtitle}
                    to={`/case-study/${slug}`}
                  >
                    {hero.quote}
                  </CardCaseStudy>
                </Col>
              );
            })}
          </Row>
          <Col xs="12" className="text-center mt-0 mt-lg-5 ">
            <Link
              to="/case-study"
              fontSize={2}
              fontWeight={500}
              className="d-inline-flex align-items-center"
            >
              <Span text-color="secondary">
                <i className="fas fa-comment-alt mr-2"></i>
                <span>See all case studies</span>
              </Span>
            </Link>
          </Col>
        </Container>
      </Section>
    </>
  );
};

export default Testimonial;
